var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tables-basic"},[_c('h1',{staticClass:"page-title mt-10 mb-6"},[_vm._v("Rozliczenia")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"employee-list mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Aktualne kwoty do możliwego rozliczenia")])]),_c('v-card-text',{staticClass:"pa-3 pb-0"},[_vm._v("Aktualny poziom prowizji od sprzedaży: "),_c('b',[_vm._v(_vm._s(_vm.amountToSettle.commissionsLvl)+"%")])]),_c('v-card-text',{staticClass:"pa-3 pb-0"},[_vm._v("Ostatnie rozliczenie przeprowadzono: "),_c('b',[_vm._v(_vm._s(_vm.amountToSettle.lastSettleTime))]),_vm._v(" (Prowizję można rozliczyć jeden raz w każdym miesiącu)")]),_c('v-card-text',{staticClass:"pa-3 pb-0"},[_vm._v("Całkowita sprzedaż od ostatniego przeprowadzonego rozliczenia: "),_c('b',[_vm._v(_vm._s(_vm.amountToSettle.totalPurchasesNetto)+" zł")]),_vm._v(" (netto)")]),_c('v-card-text',{staticClass:"pa-3 pb-3"},[_c('span',[_vm._v(" Całkowita naliczona prowizja od ostatniego przeprowadzonego rozliczenia: "),_c('b',[_vm._v(_vm._s(_vm.amountToSettle.salesCommissionsToSettle)+" zł")]),_vm._v(" (netto) ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"background-color":"lightgreen"},attrs:{"color":"black","disabled":_vm.amountToSettle.canSettle == false,"text":""},on:{"click":function($event){return _vm.settleCommission()}}},on),[_c('v-icon',[_vm._v("mdi-cash")]),_vm._v("ROZLICZ PROWIZJĘ ")],1)]}}])},[_c('span',[_vm._v("Prowizję można rozliczyć jeden raz w każdym miesiącu.")])])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"employee-list mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Historia rozliczeń")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.orders.search),callback:function ($$v) {_vm.$set(_vm.orders, "search", $$v)},expression:"orders.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.orders.headers,"items":_vm.orders.ord,"search":_vm.orders.search,"item-key":"identifier","loading":_vm.loading,"loading-text":"Ładowanie... Proszę czekać","items-per-page":_vm.itemsPerPage,"hide-default-footer":false,"footer-props":{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.invoice == true && item.status > 2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},on:{"click":function($event){return _vm.getInvoicePdf(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-file-download-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("POBIERZ WYSTAWIONĄ FAKTURĘ")])]):_vm._e(),(item.invoice == false && item.status <= 2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},on:{"click":function($event){return _vm.goToInvoiceModal(item)}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-upload-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("WGRAJ FAKTURĘ")])]):_vm._e()]}}],null,true),model:{value:(_vm.orders.selected),callback:function ($$v) {_vm.$set(_vm.orders, "selected", $$v)},expression:"orders.selected"}})],1)],1)],1),_c('InvoiceModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInvoiceModal),expression:"showInvoiceModal"}],attrs:{"invoice":_vm.invoice},on:{"close":_vm.closeModal,"reloadhistory":_vm.reloadhistory}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }