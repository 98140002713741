<template>
  <v-footer class="mt-10" color="transparent">
    <v-row no-gutters>
        <div>&copy; Copyright {{new Date().getFullYear()}}, MWC Sp. z o.o. Kowalewicka 12, 60-002 Poznań </div>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',

  data: () => ({
  }),
}
</script>

<style src="./Footer.scss" lang="scss"/>

