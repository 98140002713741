<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Klienci</h1>

            <v-row>
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Lista klientów zarejestrowanych z Twojego programu partnerskiego</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="orders.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="orders.selected"
                                      :headers="orders.headers"
                                      :items="orders.ord"
                                      :search="orders.search"
                                      item-key="identifier"
                                      :loading="loading"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage"
                                      :hide-default-footer="false"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

        </div>
    </v-container>
</template>

<script>
    import Service from '@/services/Service'

    export default {
        name: 'Orders',
        components: {

        },
        data() {
            return {
                orders: [],
                itemsPerPage: 10,
                loading: true
            }
        },
        async mounted() {
            try {
                 await this.loadRecords();
            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        watch: {
            itemsPerPage: {
                handler() {
                    if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                        this.loadRecords();
                    }
                },
                deep: true,
            },
        },
        methods: {
            async loadRecords() {
                try {
                    this.loading = true;
                    var result = (await Service.getPartnerClients()).data;

                    this.orders = {
                        selected: [],
                        search: '',
                        headers: [
                            { text: 'Nazwa klienta', value: 'name' },
                            { text: 'NIP', value: 'taxId' },
                            { text: 'Liczba zakupów', value: 'totalPurchasesCount' },
                            { text: 'Całkowita sprzedaż netto [zł]', value: 'totalPurchasesNetto' },
                            { text: 'Całkowita prowizja netto [zł]', value: 'totalSalesCommissionsNetto' },
                            { text: 'Prowizja do rozliczenia netto [zł]', value: 'salesCommissionsToSettle' },
                        ],
                        ord: result,
                    },
                        this.loading = false
                } catch (e) {
                    console.log(e)
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            }
        }
    }

</script>

<style src="./Basic.scss" lang="scss">
</style>
