<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Materiały do pobrania</h1>

            <v-row>
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Lista przydatnych materiałów</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="materials.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="materials.selected"
                                      :headers="materials.headers"
                                      :items="materials.ord"
                                      :search="materials.search"
                                      item-key="identifier"
                                      :loading="loading"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage"
                                      :hide-default-footer="false"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="getMaterial(item)" v-on="on">
                                            <v-icon color="black">mdi-download</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>POBIERZ PLIK</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

        </div>
    </v-container>
</template>

<script>
    import Service from '@/services/Service'

    export default {
        name: 'Materials',
        components: {

        },
        data() {
            return {
                materials: [],
                itemsPerPage: 10,
                loading: true,
            }
        },
        async mounted() {
            try {
                await this.loadRecords();
            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        watch: {
            itemsPerPage: {
                handler() {
                    if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                        this.loadRecords();
                    }
                },
                deep: true,
            },
        },
        methods: {
            async loadRecords() {
                try {
                    this.loading = true;
                    var result = (await Service.getPartnersMaterials()).data;

                    this.materials = {
                        selected: [],
                        search: '',
                        headers: [
                            {
                                text: 'Id',
                                align: 'start',
                                sortable: true,
                                value: 'id',
                            },
                            { text: 'Nazwa', value: 'fileName' },
                            { text: 'Opis', value: 'name' },
                            { text: 'Typ', value: 'type' },
                            { text: '', value: 'actions' },
                        ],
                        ord: result,
                    },
                        this.loading = false
                } catch (e) {
                    console.log(e)
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async getMaterial(item) {
                try {
                    var result = (await Service.downloadMaterial(item.id));

                    if (result.status == 200) {
                        const contentType = result.headers['content-type'];
                        var a = document.createElement('a');
                        var blob = new Blob([result.data], { 'type': contentType });

                        a.href = window.URL.createObjectURL(blob);
                        a.download = item.name;
                        a.click();
                    }
                } catch (e) {
                    alert(e.response.status + " - " + e.response.data.toString());
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
        }
    }

</script>

<style src="../Clients/Basic.scss" lang="scss">
</style>
