<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Dane partnera</h1>

          <v-row v-if="showGlobalSettingsModals">
              <v-col cols="12">
                  <v-card>
                      <v-card-title class="pa-6 pb-3">
                          <p>Twoje dane</p>
                          <v-spacer></v-spacer>
                          <v-btn @click="changeCustomerData()" style="margin-left:50px;" color="blue">Zmień dane</v-btn>
                          <v-btn @click="changePassword()" style="margin-left:50px;">Zmień hasło do konta</v-btn>
                      </v-card-title>
                      <v-card-text class="pa-3 pb-0" center>Nazwa: <span style="font-weight: bold;">{{customer.partnerName}}</span> </v-card-text>
                      <v-card-text class="pa-3 pb-0">Adres: <span style="font-weight: bold;">{{customer.partnerStreet}}, {{customer.partnerZipcode}} {{customer.partnerCity}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Nip: <span style="font-weight: bold;">{{customer.partnerTaxId}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Email: <span style="font-weight: bold;">{{customer.partnerEmail}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Typ partnera: <span style="font-weight: bold;">{{customer.typeName}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Telefon: <span style="font-weight: bold;">{{customer.partnerPhone != undefined && customer.partnerPhone.length > 0 ? customer.partnerPhone : 'Brak'}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Status: <span style="font-weight: bold;">{{customer.activated == true ? 'Aktywny' : 'Nieaktywny'}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Data rejestracji konta: <span style="font-weight: bold;">{{formatDateToDisplay(customer.registrationTime)}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-3">Ostatnie logowanie: <span style="font-weight: bold;">{{formatDateToDisplay(customer.lastVisit)}}</span></v-card-text>
                  </v-card>
              </v-col>
          </v-row>

          <v-row v-if="showGlobalSettingsModals">
              <v-col cols="12">
                  <v-card>
                      <v-card-title class="pa-6 pb-3">
                          <p>Aktualne dane Twojego programu partnerskiego</p>
                          <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text class="pa-3 pb-0" center>
                          Twój link partnerski: <span style="font-weight: bold;"><a :href="discount.partnerDiscountUrl" target="_blank">{{discount.partnerDiscountUrl}}</a></span>
                          <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                  <v-btn color="grey" text @click="copyLinkToClipboard()" v-on="on">
                                      <v-icon>mdi-content-copy</v-icon>
                                  </v-btn>
                              </template>
                              <span>KOPIUJ</span>
                          </v-tooltip>
                      </v-card-text>

                      <v-card-text class="pa-3 pb-0">Aktualny kod rabatowy: <span style="font-weight: bold;">{{discount.partnerDiscountCode}}</span>
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn color="grey" text @click="copyCodeToClipboard()" v-on="on">
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                </template>
                                <span>KOPIUJ</span>
                              </v-tooltip>
                      </v-card-text>
                      <v-card-text class="pa-3 pb-0">Ważność linku partnerskiego i kodu rabatowego: <span style="font-weight: bold;">{{formatDateToDisplay(discount.discountEndTime)}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Aktualny poziom rabatu dla Twoich klientów: <span style="font-weight: bold;">{{discount.discountForClientPercentage}}%</span></v-card-text>
                      <v-card-text class="pa-3 pb-3">Aktualny poziom Twojej prowizji: <span style="font-weight: bold;">{{discount.partnerSalesCommissionLevel}}%</span></v-card-text>
                  </v-card>
              </v-col>
          </v-row>

          <v-row v-if="showUserPasswordModal">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-1">
                          <p>Zmiana hasła</p>
                          <v-spacer></v-spacer>
                          <v-btn @click="closeModal()">Zamknij</v-btn>
                      </v-card-title>
                      <form class="pa-10 pb-10">
                          <v-text-field v-model="oldpassword"
                                        :error-messages="passRules"
                                        type="password"
                                        autocomplete="off"
                                        label="Stare hasło"
                                        @input="$v.oldpassword.$touch()"
                                        @blur="$v.oldpassword.$touch()"
                                        required></v-text-field>
                          <v-text-field v-model="newpassword"
                                        :error-messages="passRulesNew"
                                        type="password"
                                        autocomplete="off"
                                        label="Nowe hasło"
                                        @input="$v.newpassword.$touch()"
                                        @blur="$v.newpassword.$touch()"
                                        required></v-text-field>
                          <v-text-field v-model="repeatnewdpassword"
                                        :error-messages="passRulesRepeat"
                                        type="password"
                                        autocomplete="off"
                                        label="Powtórz nowe hasło"
                                        @input="$v.repeatnewdpassword.$touch()"
                                        @blur="$v.repeatnewdpassword.$touch()"
                                        required></v-text-field>
                          <br /><br />
                          <v-btn class="mr-4"
                                 @click="submit" color="green" :disabled="oldpassword.length < 6 || newpassword.length < 6 || repeatnewdpassword.length < 6 || newpassword != repeatnewdpassword">
                              Zatwierdź
                          </v-btn>
                          <v-btn @click="clear">
                              Wyczyść pola
                          </v-btn>
                      </form>
                  </v-card>
              </v-col>
          </v-row>

          <v-row v-if="showCustomerDataModal">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-1">
                          <p>Zmiana danych korespondencyjnych</p>
                          <v-spacer></v-spacer>
                          <v-btn @click="closeModalCustomerData()">Zamknij</v-btn>
                      </v-card-title>
                      <form class="pa-10 pb-10">
                          <v-text-field v-model="customerName"
                                        :error-messages="nameErrors"
                                        label="Nazwa firmy"
                                        required
                                        @input="$v.customerName.$touch()"
                                        @blur="$v.customerName.$touch()"></v-text-field>
                          <v-text-field v-model="adress"
                                        :error-messages="adressErrors"
                                        label="Adres"
                                        required
                                        @input="$v.adress.$touch()"
                                        @blur="$v.adress.$touch()"></v-text-field>
                          <v-text-field v-model="zipcode"
                                        :error-messages="zipcodeErrors"
                                        label="Kod pocztowy format(XX-XXX)"
                                        required
                                        @input="$v.zipcode.$touch()"
                                        @blur="$v.zipcode.$touch()"></v-text-field>
                          <v-text-field v-model="city"
                                        :error-messages="cityErrors"
                                        label="Miasto"
                                        required
                                        @input="$v.city.$touch()"
                                        @blur="$v.city.$touch()"></v-text-field>
                          <v-text-field v-model="nip"
                                        :error-messages="nipErrors"
                                        label="NIP"
                                        @keypress="onlyNumber"
                                        @input="$v.nip.$touch()"
                                        @blur="$v.nip.$touch()"></v-text-field>
                          <v-text-field v-model="phone"
                                        @keypress="onlyNumber"
                                        label="Telefon"
                                        @input="$v.phone.$touch()"
                                        @blur="$v.phone.$touch()"></v-text-field>
                          <v-select v-model="selectedPartnerType"
                                    :items="types"
                                    item-text="name"
                                    :error-messages="selectErrors"
                                    label="Rodzaj przedsiębiorstwa"
                                    required
                                    @change="changeSelectedType()"
                                    ></v-select>
                          <br /><br />
                          <v-btn class="mr-4"
                                 @click="submitCustomerData" color="green" :disabled="customerName.length < 3 || adress.length < 3 || city.length < 3 || nip.length != 10 || zipcode.length < 5">
                              Zatwierdź
                          </v-btn>
                      </form>
                  </v-card>

              </v-col>
          </v-row>


      </div>
  </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import { validationMixin } from 'vuelidate'
    import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'

export default {
name: 'Settings',
components: {

},
mixins: [validationMixin],
validations: {
    customerName: { required, minLength: minLength(3) },
    adress: { required, minLength: minLength(3) },
    city: { required, minLength: minLength(3) },
    nip: { minLength: minLength(10), maxLength: maxLength(10) },
    zipcode: { required, minLength: minLength(5) },
    selectedPartnerType: { required },
    oldpassword: { required, minLength: minLength(6) },
    newpassword: { required, minLength: minLength(6) },
    repeatnewdpassword: { required, minLength: minLength(6), sameAsPassword: sameAs('newpassword') },
},
  data() {
    return {
        customer: {},
        discount: {},
        main: [],
        showCustomerDataModal: false,
        showUserPasswordModal: false,
        oldpassword: '',
        newpassword: '',
        repeatnewdpassword: '',
        customerName: '',
        zipcode: '',
        adress: '',
        city: '',
        nip: '',
        phone: '',
        selectedPartnerType: {},
        types: [],
        showGlobalSettingsModals: true
    }
  },
computed: {
    nameErrors() {
        const errors = []
        if (!this.$v.customerName.$dirty) return errors
        !this.$v.customerName.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
        !this.$v.customerName.required && errors.push('Pole jest wymagane.')
        return errors
    },
    selectErrors() {
        const errors = []
        if (!this.$v.selectedPartnerType.$dirty) return errors
        !this.$v.select.selectedPartnerType.required && errors.push('Pole wymagane')
        return errors
    },
    cityErrors() {
        const errors = []
        if (!this.$v.city.$dirty) return errors
        !this.$v.city.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
        !this.$v.city.required && errors.push('Pole jest wymagane.')
        return errors
    },
    adressErrors() {
        const errors = []
        if (!this.$v.adress.$dirty) return errors
        !this.$v.adress.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
        !this.$v.adress.required && errors.push('Pole jest wymagane.')
        return errors
    },
    zipcodeErrors() {
        const errors = []
        if (!this.$v.zipcode.$dirty) return errors
        !this.$v.zipcode.minLength && errors.push('Pole nie może mieć mniej niż 5 znaków')
        !this.$v.zipcode.required && errors.push('Pole jest wymagane.')
        return errors
    },
    nipErrors() {
        const errors = []
        if (!this.$v.nip.$dirty) return errors
        !this.$v.nip.minLength && errors.push('Pole nie może mieć mniej niż 10 znaków')
        !this.$v.nip.maxLength && errors.push('Pole nie może mieć więcej niż 10 znaków')
        return errors
    },
    passRules() {
        const errors = []
        if (!this.$v.oldpassword.$dirty) return errors
        !this.$v.oldpassword.minLength && errors.push('Pole nie może mieć mniej niż 6 znaków')
        return errors
    },
    passRulesNew() {
        const errors = []
        if (!this.$v.newpassword.$dirty) return errors
        !this.$v.newpassword.minLength && errors.push('Pole nie może mieć mniej niż 6 znaków')
        return errors
    },
    passRulesRepeat() {
        const errors = []
        if (!this.$v.repeatnewdpassword.$dirty) return errors
        !this.$v.repeatnewdpassword.minLength && errors.push('Pole nie może mieć mniej niż 6 znaków')
        !this.$v.repeatnewdpassword.sameAsPassword && errors.push('Hasła nie są takie same')
        return errors
    }
},
async mounted() {
    try {
        this.main = (await Service.getPartnerData()).data,
        this.customer = this.main.partner,
        this.types = this.main.partnerTypes,
        this.discount = this.main.partnerDiscount,
        this.selectedPartnerType = this.getCustomerType()
    } catch (e) {
        if (e.response.status == 401)
            this.$router.push({ name: 'Login' })
    }
  },
  methods: {
      copyLinkToClipboard() {
          navigator.clipboard.writeText(this.discount.partnerDiscountUrl);
          alert("Skopiowano link partnerski do schowka");
      },
      copyCodeToClipboard() {
          navigator.clipboard.writeText(this.discount.partnerDiscountCode);
          alert("Skopiowano kod rabatowy do schowka");
      },
      changeSelectedType() {
          var pac = {};
          if (typeof (this.selectedPartnerType) === 'string') {
              pac = this.types.find(x => x.name == this.selectedPartnerType);
          }
          else {
              pac = this.selectedPartnerType;
          }

          this.selectedPartnerType = pac;
      },
      getCustomerType() {
          return this.types.find(x => x.id == this.customer.type);
      },
      async submitCustomerData() {
          try {
              if (confirm('Czy zmienić dane korespondencyjne?')) {

                  if (this.nip.length > 0) {
                      if (this.nip.length != 10) {
                          alert("Błędny numer NIP");
                          return;
                      }
                  }

                  var pac = {};
                  if (typeof (this.selectedPartnerType) === 'string') {
                      pac = this.types.find(x => x.name == this.selectedPartnerType);
                  }
                  else {
                      pac = this.selectedPartnerType;
                  }

                  const user = {
                      id: this.customer.id,
                      partnerName: this.customerName,
                      partnerStreet: this.adress,
                      partnerZipcode: this.zipcode,
                      partnerCity: this.city,
                      partnerTaxId: this.nip,
                      partnerType: pac.id,
                      partnerPhone: this.phone,
                      typeName: pac.name
                  }

                  var result = (await Service.changePartnerData(user)).data;
                  if (Object.keys(result).length > 0) {
                      this.customer.partnerName = result.partnerName;
                      this.customer.partnerZipcode = result.partnerZipcode;
                      this.customer.partnerStreet = result.partnerStreet;
                      this.customer.partnerCity = result.partnerCity;
                      this.customer.partnerTaxId = result.partnerTaxId;
                      this.customer.typeName = result.typeName;
                      this.customer.type = result.partnerType;
                      this.selectedPartnerType = this.getCustomerType();
                      this.showCustomerDataModal = false;
                      this.showGlobalSettingsModals = true;
                  }
              }
              else {
                  return;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      onlyNumber($event) {
          let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
          if ((keyCode < 48 || keyCode > 57)) {
              $event.preventDefault();
          }
      },
      closeModalCustomerData() {
          this.customerName = '';
          this.zipcode = '';
          this.adress = '';
          this.city = '';
          this.nip = '';
          this.phone = '';
          this.selectedPartnerType = this.getCustomerType();
          this.showCustomerDataModal = false;
          this.showGlobalSettingsModals = true;
      },
      async submit() {
          try {
              if (confirm('Czy zmienić hasło do tego konta? Po zmianie hasła nastąpi wylogowanie z systemu.')) {

                  var temp = {
                      id: this.customer.id,
                      password: this.oldpassword,
                      passwordNew1: this.newpassword,
                      passwordNew2: this.repeatnewdpassword
                  };

                  var result = (await Service.changePasswordForAdmin(temp));
                  if (result.status == 200) {
                      alert("Poprawnie zmianiono hasło. Zaraz nastąpi wylowowanie z konta. Zaoguj się nowym hasłem ponownie!");

                      var res = (await Service.logout());
                      if (res.status == 200) {
                          this.$router.push('/login');
                      }
                  }

                  this.newpassword = '';
                  this.oldpassword = '';
                  this.repeatnewdpassword = '';
                  this.showGlobalSettingsModals = true;
              }
              else {
                  return;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      clear() {
          this.newpassword = '';
          this.oldpassword = '';
          this.repeatnewdpassword = '';
      },
      closeModal() {
          this.newpassword = '';
          this.oldpassword = '';
          this.repeatnewdpassword = '';
          this.showUserPasswordModal = false;
          this.showGlobalSettingsModals = true;
      },
      changeCustomerData() {
          this.customerName = this.customer.partnerName;
          this.zipcode = this.customer.partnerZipcode;
          this.adress = this.customer.partnerStreet;
          this.city = this.customer.partnerCity;
          this.nip = this.customer.partnerTaxId;
          this.phone = this.customer.partnerPhone;
          this.selectedPartnerType = this.getCustomerType();
          this.showGlobalSettingsModals = false;
          this.showCustomerDataModal = true;
      },
      changePassword() {
          this.showGlobalSettingsModals = false;
          this.showUserPasswordModal = true;
      },
      formatDateToDisplay(value) {
          var date = new Date(value)
          var d = date.toLocaleString('default', {
              year: 'numeric',
              month: 'long',
              weekday: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
          });

          return d;
      },
  }
}

</script>

<style scope>


</style>
