import Api from '@/services/Api'

export default {
    // auth
    activateAccount(req) {
        return Api().post('auth/activateAccount', req)
    },
    resetPassForAdmin(req) {
        return Api().post('auth/resetPassForAdmin', req)
    },
    passwordReset(req) {
        return Api().post('auth/resetPassword', req)
    },
    register(req) {
        return Api().post('auth/register', req)
    },
    loginUser(req) {
        return Api().post('auth/login', req)
    },
    changePasswordForAdmin(req) {
        return Api().post(`auth/changePasswordForAdmin`, req)
    },
    getEmailFromActivationLink(id) {
        return Api().get(`auth/getEmailFromActivationLink/${id}`)
    },
    getEmailFromResetLink(id) {
        return Api().get(`auth/getEmailFromResetLink/${id}`)
    },
    logout() {
        return Api().post('auth/logout')
    },
    getPartnerName() {
        return Api().get(`auth/getPartnerName`)
    },
    getDataFromGus(nip) {
        return Api().get(`auth/getDataFromGus/${nip}`)
    },

    // partners data settings
    getPartnerData() {
        return Api().get(`settings/getPartnerData`)
    },
    changePartnerData(data) {
        return Api().post(`settings/changePartnerData`, data)
    },

    // clients
    getPartnerClients() {
        return Api().get(`clients/getPartnerClients`)
    },

    // billings
    getPartnerBillings() {
        return Api().get(`billings/getPartnerBillings`)
    },
    getPartnerAmountToSettle() {
        return Api().get(`billings/getPartnerAmountToSettle`)
    },
    settlePartnerCommission(data) {
        return Api().post(`billings/settlePartnerCommission`, data)
    },
    getDataToInvoice(id) {
        return Api().get(`billings/getDataToInvoice/${id}`)
    },
    importInvoicePdfFile(id, req) {
        return Api().post(`billings/importInvoicePdfFile/${id}`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    downloadInvoice(req) {
        return Api().post(`billings/downloadInvoice`, req, { responseType: 'arraybuffer' })
    },

    // materials
    downloadMaterial(id) {
        return Api().get(`materials/downloadMaterials/${id}`, { responseType: 'arraybuffer' })
    },
    getPartnersMaterials() {
        return Api().get(`materials/getMaterialsList`)
    }

}
