<template>
    <v-app>
        <v-container fluid id="login">
            <v-row no-gutters>
                <v-col cols="3" class="main-part d-none d-md-none d-lg-flex">
                    <div class="d-flex">
                        <v-img src="@/assets/sygnali_logo_450.png" contain style="width:60%"></v-img>
                        <span style="color:white">PROGRAM PARTNERSKI</span>
                        <span style="color:white">PLATFORMY OCHRONY SYGNALISTÓW</span>
                    </div>
                </v-col>
                <v-col cols="12" lg="9" class="login-part d-flex align-center justify-center">
                    <v-row no-gutters class="align-start">
                        <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
                            <div class="login-wrapper pt-md-4 pt-0">
                                <v-tabs grow>
                                    <v-tabs-slider></v-tabs-slider>
                                    <v-tab :href="`#tab-login`">
                                        REJESTRACJA PARTNERA - KROK 2
                                    </v-tab>
                                    <v-tab-item :value="'tab-login'">
                                        <v-form>
                                            <v-container>
                                                <v-row class="flex-column">
                                                    <v-col cols="12" >
                                                    </v-col>
                                                    <v-form>
                                                        <v-col>
                                                            <v-text-field v-bind:value="email"
                                                                          label="Adres Email"
                                                                          required
                                                                          disabled></v-text-field>

                                                            <v-row>
                                                                <v-col cols="7">
                                                                    <v-text-field v-model="nip"
                                                                                  :error-messages="nipErrors"
                                                                                  label="NIP"
                                                                                  @keypress="onlyNumber"
                                                                                  @input="$v.nip.$touch()"
                                                                                  @blur="$v.nip.$touch()"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="3">
                                                                    <v-btn :disabled="nip.length != 10" @click="getGusData()"> Pobierz z GUS </v-btn>
                                                                </v-col>
                                                            </v-row>

                                                            <v-text-field v-model="companyName"
                                                                          :error-messages="nameErrors"
                                                                          label="Nazwa firmy/instytucji"
                                                                          required
                                                                          @input="$v.companyName.$touch()"
                                                                          @blur="$v.companyName.$touch()"></v-text-field>
                                                            <v-text-field v-model="address"
                                                                          :error-messages="addressErrors"
                                                                          label="Adres"
                                                                          required
                                                                          @input="$v.address.$touch()"
                                                                          @blur="$v.address.$touch()"></v-text-field>
                                                            <v-text-field v-model="zipcode"
                                                                          :error-messages="zipcodeErrors"
                                                                          label="Kod pocztowy format(XX-XXX)"
                                                                          required
                                                                          @input="$v.zipcode.$touch()"
                                                                          @blur="$v.zipcode.$touch()"></v-text-field>
                                                            <v-text-field v-model="city"
                                                                          :error-messages="cityErrors"
                                                                          label="Miasto"
                                                                          required
                                                                          @input="$v.city.$touch()"
                                                                          @blur="$v.city.$touch()"></v-text-field>
                                                            <v-text-field v-model="phone"
                                                                          @keypress="onlyNumber"
                                                                          label="Telefon"
                                                                          @input="$v.phone.$touch()"
                                                                          @blur="$v.phone.$touch()"></v-text-field>
                                                            <v-select v-model="selectedPartnerType"
                                                                      :items="partnersTypes"
                                                                      item-text="name"
                                                                      label="Rodzaj przedsiębiorstwa"
                                                                      ></v-select>
                                                            <v-text-field v-model="password"
                                                                          :rules="passRulesNew"
                                                                          type="password"
                                                                          label="Ustaw hasło"
                                                                          hint="Minimum 6 znaków"
                                                                          @input="$v.password.$touch()"
                                                                          @blur="$v.password.$touch()"
                                                                          required></v-text-field>
                                                            <v-text-field v-model="password2"
                                                                          :error-messages="passRulesRepeat"
                                                                          type="password"
                                                                          autocomplete="off"
                                                                          label="Powtórz hasło"
                                                                          @input="$v.password2.$touch()"
                                                                          @blur="$v.password2.$touch()"
                                                                          required></v-text-field>
                                                        </v-col>
                                                        <v-col class="d-flex justify-space-between" style="margin-bottom: 10px;">
                                                            <v-btn class="text-capitalize"
                                                                   large
                                                                   :disabled="password.length === 0 || password2.length === 0 || email.length === 0 || companyName.length == 0 || address.length == 0 || zipcode.length == 0 || city.length == 0 || nip.length != 10 || password != password2"
                                                                   color="primary"
                                                                   @click="activate">
                                                                Aktywuj konto partnerskie
                                                            </v-btn>
                                                        </v-col>
                                                    </v-form>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-tab-item>
                                </v-tabs>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    import Service from '@/services/Service'
    import { validationMixin } from 'vuelidate'
    import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
export default {
    name: 'Registration',
    mixins: [validationMixin],
        validations: {
            companyName: { required, minLength: minLength(3) },
            address: { required, minLength: minLength(3) },
            city: { required, minLength: minLength(3) },
            nip: { minLength: minLength(10), maxLength: maxLength(10) },
            zipcode: { required, minLength: minLength(5) },
            password: { required, minLength: minLength(6) },
            selectedPartnerType: { required },
            password2: {
                required, minLength: minLength(6), sameAsPassword: sameAs('password')
            },
        },
        computed: {
            //selectErrors() {
            //    const errors = []
            //    if (!this.$v.selectedPartnerType.$dirty) return errors
            //    !this.$v.select.selectedPartnerType.required && errors.push('Pole wymagane')
            //    return errors
            //},
            nameErrors() {
                const errors = []
                if (!this.$v.companyName.$dirty) return errors
                !this.$v.companyName.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
                !this.$v.companyName.required && errors.push('Pole jest wymagane.')
                return errors
            },
            cityErrors() {
                const errors = []
                if (!this.$v.city.$dirty) return errors
                !this.$v.city.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
                !this.$v.city.required && errors.push('Pole jest wymagane.')
                return errors
            },
            addressErrors() {
                const errors = []
                if (!this.$v.address.$dirty) return errors
                !this.$v.address.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
                !this.$v.address.required && errors.push('Pole jest wymagane.')
                return errors
            },
            zipcodeErrors() {
                const errors = []
                if (!this.$v.zipcode.$dirty) return errors
                !this.$v.zipcode.minLength && errors.push('Pole nie może mieć mniej niż 5 znaków')
                !this.$v.zipcode.required && errors.push('Pole jest wymagane.')
                return errors
            },
            nipErrors() {
                const errors = []
                if (!this.$v.nip.$dirty) return errors
                !this.$v.nip.minLength && errors.push('Pole nie może mieć mniej niż 10 znaków')
                !this.$v.nip.maxLength && errors.push('Pole nie może mieć więcej niż 10 znaków')
                return errors
            },
            passRulesNew() {
                const errors = []
                if (!this.$v.password.$dirty) return errors
                !this.$v.password.minLength && errors.push('Pole nie może mieć mniej niż 6 znaków')
                return errors
            },
            passRulesRepeat() {
                const errors = []
                if (!this.$v.password2.$dirty) return errors
                !this.$v.password2.minLength && errors.push('Pole nie może mieć mniej niż 6 znaków')
                !this.$v.password2.sameAsPassword && errors.push('Hasła nie są takie same')
                return errors
            }
        },
    data() {
      return {
        email: '',
        activationLink: '',
        companyName: '',
        password2: '',
        password: '',
        zipcode: '',
        city: '',
        address: '',
        nip: '',
        phone: '',
        partnersTypes: [],
        selectedPartnerType: {},
      }
    },
    created(){
        document.title = "partners.sygnali.pl";
    },
    async mounted() {
        try {
            var data = (await Service.getEmailFromActivationLink(this.$route.params.email)).data;
            this.email = data.email;
            this.partnersTypes = data.partnersTypes;
            this.selectedPartnerType = this.partnersTypes[0];
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            this.$router.push({ name: 'Error2' });
        }
    },
    methods: {
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) {
                $event.preventDefault();
            }
        },
        async getGusData() {
            try {
                var result = (await Service.getDataFromGus(this.nip)).data;

                if (result != null) {
                    this.companyName = result.name;
                    this.zipcode = result.zipCode;
                    this.city = result.city;
                    this.address = result.address;
                }
            } catch (e) {
                alert(e.response.status + " - " + e.response.data.toString());
                return;
            }
        },
        async activate() {
            try {

                var pac = {};
                if (typeof (this.selectedPartnerType) === 'string') {
                    pac = this.partnersTypes.find(x => x.name == this.selectedPartnerType);
                }
                else {
                    pac = this.selectedPartnerType;
                }

                const user = {
                    email: this.email,
                    password: this.password,
                    password2: this.password2,
                    companyName: this.companyName,
                    companyAddress: this.address,
                    companyZipCode: this.zipcode,
                    companyCity: this.city,
                    partnerTaxId: this.nip,
                    partnerType: pac.id,
                    phoneNumber: this.phone
                }

                var result = (await Service.activateAccount(user));

                if (result.status == 200) {
                    alert("Dziękujemy. Konto partnera zostało aktywowane. Teraz możesz się zalogować.");
                    this.$router.push('/login');
                }
            } catch (e) {
                alert(e.response.status + " - " + e.response.data.toString());
                return;
                //this.$router.push({ name: 'Error' });
            }
      }
    },
  }

</script>

<style src="./Login.scss" lang="scss" />
