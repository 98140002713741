<template>
  <v-app>
    <v-container fluid class="error-page">
      <v-row class="logo-wrapper">      
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8">
            <div class="card">
                <v-img src="@/assets/sygnali_logo_450_black.png" contain style="width:100%"></v-img>
                <p class="error-text">Brak wskazanej strony</p>
            </div>
        </v-col>
      </v-row>
  </v-container>
  </v-app>
</template>

<script>
import config from '@/config';

export default {
  name: 'Error2',
  data(){
    return{
      config
    }
  }
};
</script>

<style src="./Error.scss" lang="scss"></style>
