<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Rozliczenia</h1>

            <v-row>
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Aktualne kwoty do możliwego rozliczenia</p>
                        </v-card-title>
                        <v-card-text class="pa-3 pb-0">Aktualny poziom prowizji od sprzedaży: <b>{{amountToSettle.commissionsLvl}}%</b></v-card-text>
                        <v-card-text class="pa-3 pb-0">Ostatnie rozliczenie przeprowadzono: <b>{{amountToSettle.lastSettleTime}}</b> (Prowizję można rozliczyć jeden raz w każdym miesiącu)</v-card-text>
                        <v-card-text class="pa-3 pb-0">Całkowita sprzedaż od ostatniego przeprowadzonego rozliczenia: <b>{{amountToSettle.totalPurchasesNetto}} zł</b> (netto)</v-card-text>
                        <v-card-text class="pa-3 pb-3">
                            <span> Całkowita naliczona prowizja od ostatniego przeprowadzonego rozliczenia: <b>{{amountToSettle.salesCommissionsToSettle}} zł</b> (netto) </span>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn color="black" :disabled="amountToSettle.canSettle == false" style="background-color: lightgreen;" text @click="settleCommission()" v-on="on">
                                        <v-icon>mdi-cash</v-icon>ROZLICZ PROWIZJĘ
                                    </v-btn>
                                </template>
                                <span>Prowizję można rozliczyć jeden raz w każdym miesiącu.</span>
                            </v-tooltip>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card class="employee-list mb-1">
                        <v-card-title class="pa-6 pb-3">
                            <p>Historia rozliczeń</p>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="orders.search"
                                          append-icon="mdi-magnify"
                                          label="Szukaj"
                                          clearable
                                          single-line
                                          hide-details></v-text-field>
                        </v-card-title>
                        <v-data-table v-model="orders.selected"
                                      :headers="orders.headers"
                                      :items="orders.ord"
                                      :search="orders.search"
                                      item-key="identifier"
                                      :loading="loading"
                                      loading-text="Ładowanie... Proszę czekać"
                                      :items-per-page.sync="itemsPerPage"
                                      :hide-default-footer="false"
                                      :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip top v-if="item.invoice == true && item.status > 2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="getInvoicePdf(item)" v-on="on">
                                            <v-icon color="red">mdi-file-download-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>POBIERZ WYSTAWIONĄ FAKTURĘ</span>
                                </v-tooltip>
                                <v-tooltip top v-if="item.invoice == false && item.status <= 2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn text @click="goToInvoiceModal(item)" v-on="on">
                                            <v-icon color="green">mdi-file-upload-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>WGRAJ FAKTURĘ</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <InvoiceModal v-show="showInvoiceModal" @close="closeModal" @reloadhistory="reloadhistory" :invoice="invoice" />
        </div>
    </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import InvoiceModal from '@/components/Modals/InvoiceModal'
    export default {
        name: 'Billings',
        components: {
            InvoiceModal
        },
        data() {
            return {
                orders: [],
                itemsPerPage: 10,
                loading: true,
                amountToSettle: {},
                showInvoiceModal: false,
                invoice: {
                    mwcData: {
                        name: '',
                        adres: '',
                        nip: '',
                        phone: '',
                        email: '',
                    },
                    amount: '',
                    description: '',
                },
            }
        },
        async mounted() {
            try {
                await this.loadRecords();
                await this.loadAmountsToSettle();
            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        watch: {
            itemsPerPage: {
                handler() {
                    if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                        this.loadRecords();
                    }
                },
                deep: true,
            },
        },
        methods: {
            closeModal() {
                this.showInvoiceModal = false;
                this.invoice = {
                    mwcData: {
                        name: '',
                        adres: '',
                        nip: '',
                        phone: '',
                        email: '',
                    },
                    amount: '',
                    description: '',
                };
            },
            async reloadhistory() {
                await this.loadRecords();
            },
            async goToInvoiceModal(item) {
                await this.getDataToInvoice(item.id);
                this.showInvoiceModal = true;
            },
            async loadRecords() {
                try {
                    this.loading = true;
                    var result = (await Service.getPartnerBillings()).data;

                    this.orders = {
                        selected: [],
                        search: '',
                        headers: [
                            {
                                text: 'Id',
                                align: 'start',
                                sortable: true,
                                value: 'id',
                            },
                            { text: 'Wartość netto [zł]', value: 'amountNetto' },
                            { text: 'VAT [zł]', value: 'amountVat' },
                            { text: 'Wartość brutto [zł]', value: 'amountBrutto' },
                            { text: 'Status', value: 'statusName' },
                            { text: 'Data utworzenia rozliczenia', value: 'creationTime' },
                            { text: 'Data opłacenia', value: 'paidOnTime' },
                            { text: '', value: 'actions' },
                        ],
                        ord: result,
                    },
                        this.loading = false
                } catch (e) {
                    console.log(e)
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async getInvoicePdf(item) {
                try {
                    var temp = {
                        invoiceId: item.invoiceId,
                        billingId: item.id
                    };

                    var result = (await Service.downloadInvoice(temp));

                    if (result.status == 200) {
                        const contentType = result.headers['content-type'];
                        var a = document.createElement('a');
                        var blob = new Blob([result.data], { 'type': contentType });

                        a.href = window.URL.createObjectURL(blob);
                        a.download = item.invoiceName;
                        a.click();
                    }
                } catch (e) {
                    alert(e.response.status + " - " + e.response.data.toString());
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async loadAmountsToSettle() {
                try {
                    this.loading = true;
                    var result = (await Service.getPartnerAmountToSettle()).data;

                    this.amountToSettle = result;
                    
                    this.loading = false
                } catch (e) {
                    console.log(e)
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async getDataToInvoice(id) {
                try {
                    this.loading = true;
                    var result = (await Service.getDataToInvoice(id)).data;

                    this.invoice = result;

                    this.loading = false
                } catch (e) {
                    console.log(e)
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            async settleCommission() {
                try {
                    if (confirm('Czy na pewno rozliczyć prowizję w wysokości ' + this.amountToSettle.salesCommissionsToSettle + ' zł netto?')) {

                        var result = (await Service.settlePartnerCommission(this.amountToSettle)).data;
                        if (Object.keys(result).length > 0) {
                            this.amountToSettle = result;
                            await this.loadRecords();
                            await this.getDataToInvoice(result.id);

                            this.showInvoiceModal = true;
                        }
                    }
                    else {
                        return;
                    }
                } catch (e) {
                    alert(e.response.status + " - " + e.response.data.toString())
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            }
        }
    }

</script>

<style src="../Clients/Basic.scss" lang="scss">
</style>
