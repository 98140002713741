<template>
    <v-app-bar class="main-header"
               height="64"
               fixed
               color='primary'
               dark>
        <v-btn icon class="mx-1" @click.stop="TOGGLE_DRAWER">
            <template v-if="DRAWER_STATE">
                <v-icon style="font-size: 28px">mdi-arrow-left</v-icon>
            </template>
            <template v-else>
                <v-icon style="font-size: 28px">mdi-menu</v-icon>
            </template>
        </v-btn>
        <v-toolbar-title>
            <v-img src="@/assets/sygnali_logo_450.png" contain style="width:15%"></v-img>
            <span style="font-size: 16px;">Program partnerski</span>
        </v-toolbar-title>
        <v-icon disabled>mdi-account-tie</v-icon>
        <div disabled>{{loginUser}}</div>
        
        <v-spacer></v-spacer>
        <v-btn icon disabled>

        </v-btn>

        <v-btn icon @click="logOut" style="margin-right:30px;">
            <v-icon>mdi-logout</v-icon>
            <div>Wyloguj</div>
        </v-btn>
    </v-app-bar>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
    import config from '../../config';
    import Service from '@/services/Service'
  export default {
    name: 'Header',
    data: () => ({
      config,
      searchCollapse: true,
      notificationsBadge: true,
      messageBadge: true,
      loginUser: ''
    }),
    computed: {
      ...mapState(['drawer']),
      DRAWER_STATE :{
        get() {
          return this.drawer
        },
        },
    },
    async mounted() {
        try {
            var result = (await Service.getPartnerName()).data;
            this.loginUser = result;

            var partner = {
                name: result
            };

            this.$store.commit('changePartnerData', partner);

        } catch (e) {
           console.log(e)
        }
    },
    methods: {
      ...mapActions([ 'TOGGLE_DRAWER' ]),
      async logOut() {
          try {
              var result = (await Service.logout());

              if (result.status == 200) {
                  this.$router.push('/login');
              }

          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());
          }
      },
    }
  };
</script>

<style src="./Header.scss" lang="scss"></style>
