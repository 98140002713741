<template>
    <v-app>
        <v-container fluid>
            <div>
                <transition class="modal-fade">
                    <div class="modal-backdrop">
                        <section class="modal-body" id="modalDescription">
                            <div>
                                <div>
                                    <v-card-title class="pa-3 pb-0"><p>Twoje rozliczenie prowizji zostało utworzone!</p></v-card-title>
                                    <v-card-text class="pa-3 pb-0">Data utworzenia rozliczenia: <span style="font-weight: bold;">{{new Date().toLocaleDateString("pl-PL")}}, godz. {{new Date().toLocaleTimeString()}}</span></v-card-text>
                                    <v-card-text class="pa-3 pb-0">Aby rozliczyć środki z uzyskanej prowizji wystaw fakturę na firmę:</v-card-text>
                                    <v-card-text class="pa-3 pb-0">{{invoice.mwcData.name}}</v-card-text>
                                    <v-card-text style="padding: 0 0 0 13px;">Ardes: {{invoice.mwcData.adres}}</v-card-text>
                                    <v-card-text style="padding: 0 0 0 13px;">NIP: {{invoice.mwcData.nip}}</v-card-text>
                                    <v-card-text style="padding: 0 0 0 13px;" >Telefon: {{invoice.mwcData.phone}}</v-card-text>
                                    <v-card-text style="padding: 0 0 0 13px;">Email: {{invoice.mwcData.email}}</v-card-text>
                                    <v-card-text class="pa-3 pb-0">na kwotę: <span style="font-weight: bold;">{{invoice.amount}} zł netto</span></v-card-text>
                                    <v-card-text class="pa-3 pb-3">o treści: <span style="font-weight: bold;">{{invoice.description}}</span></v-card-text>                              
                                    <br />
                                    <v-card-text class="pa-3 pb-0" style="text-align:justify;font-size:0.8em;"><hr>MWC Sp. z o.o. @ Sygnali.pl - Program Partnerski</v-card-text>
                                </div>
                                <br /><br />
                                <v-row align="center" justify="space-around" style="padding-bottom:15px;">
                                    <v-btn color="default" @click="close()" style="max-width:100px"> Zamknij</v-btn>
                                    <v-btn color="primary" @click="importInvoicePdf()"  style="max-width:300px"> Wgraj fakturę (pdf)</v-btn>
                                </v-row>
                            </div>
                        </section>
                    </div>
                </transition>
            </div>
        </v-container>
    </v-app>
</template>

<script>
    import Service from '@/services/Service'
    export default {
        name: 'InvoiceModal',
        props: {
            invoice: Object
        },
        data() {
            return {
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            async saveElement(files) {
                try {
                    let formData = new FormData();
                    for (var i = 0; i < files.length; i++) {
                        let file = files[i];
                        formData.append('files[' + i + ']', file);
                    }

                    formData.append("billingId", this.invoice.billingId);
                    formData.append("partnerId", this.invoice.partnerId);

                    var result = (await Service.importInvoicePdfFile(this.invoice.billingId, formData)).data;
                    if (result == true) {
                        this.$emit('close');
                        this.$emit('reloadhistory');
                        alert("Dziękujemy! Poprawnie wgrano fakturę do rozliczenia.");
                    }
                } catch (e) {
                    alert(e.response.status + " - " + e.response.data.toString());

                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
            },
            importInvoicePdf() {
                let input = document.createElement('input');
                input.type = 'file';
                input.accept = '.pdf';
                input.multiple = false;
                input.click();
                input.addEventListener('change', (event) => {
                    if (event.target.files[0].size <= 5000000) {
                        if (this.checkMimeType(event.target.files[0].type)) {
                            this.saveElement(event.target.files);
                        }
                        else {
                            alert("Nieobsługiwany typ pliku. Pliki obsługiwane to: PDF")
                        }
                    } else {
                        alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 5MB");
                    }
                });
            },
            checkMimeType(type) {
                if (type != "application/pdf") {
                    return false;
                } else {
                    return true;
                }
            },
            
        },
    };
</script>

<style scoped>
    .modalName {
    font-weight:bold;
    text-align: center;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal-body {
        position: center;
        padding: 20px 10px;
        background-color: white;
        max-width: 920px;
        min-width: 450px;
        max-height: calc(100vh - 10px);
        font-size: 14px;
    }

    input[type=button] {
    margin-left: 5px;
    }

    .slotBody {
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }
</style>