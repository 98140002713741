import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/components/Layout/Layout';

// Pages
import Login from "@/pages/Login/Login";
import Clients from '@/pages/Clients/Basic';
import Error from "@/pages/Error/Error";
import Error2 from "@/pages/Error/Error2";
import Registration from '@/pages/Login/Registration';
import ResetPassword from '@/pages/Login/ResetPassword';
import Reset from '@/pages/Login/Reset';
import Settings from '@/pages/Settings/Settings';
import Billings from '@/pages/Billings/Billings';
import Materials from '@/pages/Materials/Materials';
import Help from '@/pages/Help/HelpPage';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/clients',
        name: 'Clients',
        component: Clients
      },
      {
        path: '/billings',
        name: 'Billings',
        component: Billings
      },
      {
        path: '/materials',
        name: 'Materials',
        component: Materials
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings
      },
      {
        path: '/help',
        name: 'HelpPage',
        component: Help
      },
    ],
  },
    {
      path: '*',
      name: 'Error',
      component: Error
    },
    {
        path: '/Errors',
        name: 'Error2',
        component: Error2
    },
    {
      path: '/resetpassword',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/reset/:guidreset',
      name: 'Reset',
      component: Reset,
      props: true
    },
    {
        path: '/registration/:email',
        name: 'registration',
        component: Registration,
        props: true
    },
    ],
});
