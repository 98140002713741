<template>
    <v-container fluid>
        <div class="tables-basic">
            <h1 class="page-title mt-10 mb-6">Pomoc</h1>
                <v-row>
                    <v-col cols="12">
                        <v-card class="pa-3 pb-3">
                            <v-card-text class="small-padd">Potrzebujesz pomocy? Skontaktuj się z nami telefonicznie dzwoniąc na infolinię (pn.-pt. 8:00-16:00) <b>61 666 19 77</b> lub wysyłając zgłoszenie na adres <b>pomoc@mwc.pl</b></v-card-text>
                                <v-row no-gutters style="margin-top: 20px;">
                                    <v-col>
                                        <v-card-text class="small-padd"> <b>Pełne dane kontaktowe:</b></v-card-text>
                                        <v-card-text class="small-padd">{{mwcCompanyData.companyName}}</v-card-text>
                                        <v-card-text class="small-padd">{{mwcCompanyData.companyZipcode}} {{mwcCompanyData.companyCity}}, {{mwcCompanyData.companyAddress}}</v-card-text>
                                        <v-card-text class="small-padd"> Email: {{mwcCompanyData.email}}</v-card-text>
                                        <v-card-text class="small-padd"> Telefon: {{mwcCompanyData.phone}}</v-card-text>
                                    </v-col>
                                </v-row>
                        </v-card>
                    </v-col>
                </v-row>
        </div>
    </v-container>
</template>

<script>

export default {
name: 'HelpPage',
components: {

},
data() {
        return {
            mwcCompanyData: {
                companyName: 'MWC Spółka z ograniczoną odpowiedzialnością',
                companyZipcode: '60-002',
                companyAddress: 'Kowalewicka 12',
                companyCity: 'Poznań',
                email: 'pomoc@mwc.pl',
                phone: '61 666 19 77'
            },
        }
    },
    mounted() {

    },
    methods: {

    }
}

</script>

<style scope>
    .small-padd {
        padding: 5px;
    }

</style>
